@charset 'UTF-8';

.announcement-bar {
    background-color: #579EFF;
    color: $white;
    min-height: $announcement-bar-height;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 999;
    overflow: hidden;
    padding: 10px 30px;
    max-height: $announcement-bar-height;

    > span {
        max-width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        > span {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
    }

    a {
        color: inherit !important;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
            color: inherit !important;
        }

        &.announcement-bar__close {
            color: $white;
            font-family: 'Open Sans', sans-serif;
            font-size: 20px;
            font-weight: 600;
            padding: 0 10px;
            position: absolute;
            right: 0;
            text-decoration: none;
            top: 5px;
            &:hover {
                color: $white;
                text-decoration: none;
            }
        }

    }

    .fa-wrapper {
        margin: 0 5px;
    }

    .resend-verification-wrapper:before {
        content: "\00a0 ";
    }

    .content__icon, i {
        top: 1px;
        position: relative;
        align-items: center;
        display: inline-block;
        font-family: 'compass-icons';
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        height: 18px;
        margin-right: 8px;
    }

    button {
        &:hover {
            background-color: rgba(255, 255, 255, 0.08)
        }
        display: inline-block;
        margin-bottom: 8px;
        margin-left: 8px;
        background-color:var(--sidebar-text-active-border);
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        padding: 4px 8px;
        border: 1px solid #FFFFFF;
        height: 24px;
        box-sizing: border-box;
        border-radius: 4px;
        background-color: inherit
    }
}

.announcement-bar-critical {
    background-color: #F74343;
}

.announcement-bar-success {
    background-color: #06D6A0;
    ;
}

.announcement-bar-advisor {
    background-color: #579EFF;
}

.announcement-bar-advisor-ack {
    background-color: #06D6A0;
}

.announcement-bar-general {
    background-color: #3D3C40;
}

.announcement-bar__link {
    margin-left: 4px;
}

.advisor-icon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    margin-bottom: 2px;
}
